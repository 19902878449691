// @font-face {
//   font-family: "DecimaMonoPro";
//   src: url("fonts/DecimaMonoPro/DecimaMonoPro.woff2") format("woff2");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "DecimaMonoPro";
//   src: url("fonts/DecimaMonoPro/DecimaMonoPro.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "TTSupermolot";
//   src: url("fonts/TTSupermolot/TTSupermolot.woff2") format("woff2");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "TTSupermolot";
//   src: url("fonts/TTSupermolot/TTSupermolot.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "work-sans";
//   src: url("fonts/work-sans/work-sans.woff2") format("woff2");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }
// @font-face {
//   font-family: "work-sans";
//   src: url("fonts/work-sans/work-sans.woff") format("woff");
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

::selection {
  background: #fd2635 !important;
  color: white !important;
}

html,
body {
  font-family: "Gordita", system-ui, -apple-system, Segoe UI, Roboto, Ubuntu,
    Cantarell, Noto Sans, sans-serif, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  scroll-behavior: smooth;
}

input {
  outline: none !important;
}

.wrapper {
  padding-left: 140px !important;
  padding-right: 140px !important;
  max-width: 1668px !important;
  margin: auto !important;
  @media (max-width: 1024px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.mob-object-right {
  @media (max-width: 768px) {
    object-position: 65% !important;
  }
}
@mixin letter-spacing($spacing) {
  letter-spacing: calc(-1em * 0.001 * #{$spacing});
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
.a {
  text-decoration: none !important;
  font-family: "DecimaMonoPro";
}

h1,
.h1 {
  font-size: responsive 35px 70px;
  line-height: responsive 50px 64px;
  font-weight: 300;
  font-family: "TTSupermolot";
}

h2,
.h2 {
  font-size: responsive 26px 48px;
  line-height: responsive 26px 48px;
  font-weight: 300;
  font-family: "TTSupermolot";
}

.header-title {
  font-size: responsive 15px 28px;
  line-height: responsive 15px 28px;
  font-weight: 300;
  font-family: "TTSupermolot";
}

h3,
.h3 {
  font-size: responsive 17px 20px;
  font-family: "DecimaMonoPro";
  line-height: responsive 17px 20px;
}

.largeText {
  font-size: responsive 28px 50px;
  font-family: "TTSupermolot";
  line-height: responsive 30px 60px;
}

.largeText2 {
  font-size: responsive 36px 48px;
  font-family: "TTSupermolot";
  line-height: responsive 39px 70px;
}

.smallText {
  font-size: responsive 17px 20px;
  font-family: ‘work-sans’, sans-serif;
  line-height: responsive 23px 30px;
}

h4,
.h4 {
  font-size: responsive 20px 25px;
  line-height: responsive 20px 25px;
  font-family: "TTSupermolot";
}

h5,
.h5 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

h6,
.h6 {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

p,
.p {
  margin-bottom: 20px;
  font-size: responsive 13px 16px;
  font-family: "work-sans", sans-serif;
}

.text-xs {
  font-size: 13px;
  @include letter-spacing(-0.3);
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.wysiwyg {
  p {
    line-height: 2em;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.main-footer a {
  white-space: nowrap;
}

.province-select {
  overflow: hidden;
  .flickity-viewport {
    overflow: visible;
  }
}

.headroom--unpinned,
.headroom--pinned,
.headroom {
  .nav-button {
    height: 50px !important;
    width: 100px !important;
  }
  padding-top: 20px;
  height: 100px;
  background-color: #afb4b0c6;
  .nav-links {
    padding-top: 0;
  }

  margin: auto !important;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  padding-left: 30px !important;
  padding-right: 30px !important;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  .logo {
    height: 60px !important;
    width: auto !important;
  }
  .book-btn {
    height: 30px !important;
    width: 100px !important;
  }
  @media (max-width: 1024px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    padding-top: 10px;
    background-color: #c5ccc7;
    height: 60px !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 40px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }
}

.headroom--top {
  .nav-button {
    height: 50px !important;
    width: 127px !important;
  }
  filter: none;
  height: 180px;
  background-color: #26326100;
  box-shadow: none;
  display: grid;
  .logo {
    height: 100px !important;
    width: 100% !important;
  }
  @media (max-width: 1023px) {
    .nav-button {
      height: 40px !important;
      width: 110px !important;
    }
    padding-top: 10px;
    height: 100px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    .logo {
      height: 100px !important;
      width: auto !important;
    }
    li {
      font-size: 14px !important;
    }
  }

  padding-left: 140px !important;
  padding-right: 140px !important;

  .logo {
    @media (max-width: 1024px) {
      font-size: 23px !important;
    }
    font-size: 29px !important;
  }
  li {
    font-size: 15px !important;
  }
}

.red-bold-text {
  p {
    font-size: responsive 50px 90px;
    line-height: responsive 45px 80px;
    font-weight: 300;
    font-family: "TTSupermolot";
    color: #fd2635 !important;
    strong {
      color: #fd2635 !important;
      -webkit-text-stroke: 1px #fd2635 !important;
      -webkit-text-fill-color: transparent !important;
    }
  }
}

.blue-bold-text {
  p {
    font-size: responsive 50px 90px;
    line-height: responsive 45px 80px;
    font-weight: 300;
    font-family: "TTSupermolot";
    color: #263261 !important;
    strong {
      color: #263261 !important;
      -webkit-text-stroke: 1px #263261 !important;
      -webkit-text-fill-color: transparent !important;
    }
  }
}

.white-bold-text-heading {
  .h1 {
    letter-spacing: responsive -5px -10px !important;
    line-height: responsive 37px 70px;
    span {
      font-size: responsive 45px 90px;
      line-height: responsive 35px 80px;
      font-weight: 300;
      font-family: "TTSupermolot";
      color: #fff !important;
    }
    strong {
      color: #fff !important;
      -webkit-text-stroke: 1px #fff !important;
      -webkit-text-fill-color: transparent !important;
    }
  }
}

.white-bold-text {
  .h1 {
    letter-spacing: responsive 0px -4px !important;
    line-height: responsive 45px 60px;
    span {
      font-size: responsive 50px 90px;
      line-height: responsive 45px 60px;
      font-weight: 300;
      font-family: "TTSupermolot";
      color: #fff !important;
    }
    strong {
      color: #fff !important;
      -webkit-text-stroke: 1px #fff !important;
      -webkit-text-fill-color: transparent !important;
    }
  }
}

.accord {
  summary {
    text-transform: uppercase !important;
    padding-top: 20px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: responsive 18px 48px;
    line-height: responsive 18px 48px;
    font-weight: 300;
    font-family: "TTSupermolot";
  }

  details[open] {
    .accord-arrows::before {
      content: "-";
      transition: all 1.5s ease-out;
    }
    padding-bottom: 20px;
    font-family: "DecimaMonoPro";
  }

  details[open] summary {
    transition: all 1.5s ease-out;
    -webkit-appearance: none;
  }

  .accord-arrows::before {
    transition: all 1.5s ease-out;
    content: "+";
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
}

/* ---- flickity-button ---- */

.flickity-button {
  border: solid #263261 1px;
  position: absolute;
  z-index: 400;
  color: #333;
}

.flickity-button:hover {
  background: rgb(193, 193, 193);
  cursor: pointer;
  scale: 102%;

  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  @media (max-width: 1024px) {
    bottom: 110px;
    width: 37px;
    height: 37px;
  }
  bottom: 130px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  @media (max-width: 1024px) {
    left: 30%;
  }
  @media (max-width: 800px) {
    left: 20%;
  }
  left: 40%;
}
.flickity-prev-next-button.next {
  right: 40%;
  @media (max-width: 1024px) {
    right: 30%;
  }
  @media (max-width: 800px) {
    right: 20%;
  }
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 50%;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 50%;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  z-index: 400;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #fff;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.mob-nav {
  display: none !important;
  @media (max-width: 1330px) {
    display: block !important;
  }
}

.main-nav {
  display: none !important;
  @media (min-width: 1330px) {
    display: block !important;
  }
}

.footerText {
  font-family: "work-sans", "sans-serif" !important;
  p {
    strong {
      font-weight: 900 !important;
    }
  }
}

.header-slide {
  .flickity-slider {
    transform: none !important;
  }
}

.gallery-cell {
  left: 0 !important;
  opacity: 0.2;
  transition: opacity 0.6s ease-in-out;
  z-index: -1;
  transform: none !important;
}

.gallery-cell.is-selected {
  opacity: 1;
  z-index: 0;
  transition: opacity 0.6s ease-in-out;
}

.img-reveal {
  @media (max-width: 1370px) {
    display: none !important;
  }
}
